import { CircularProgress, Grid } from '@mui/material';
import DashboardItem from '../shared/DashboardItem';
import { getPAdCount } from '../../api/pAd';
import { useQuery } from 'react-query';
import { getAdCount } from '../../api/ad';

const Dashboard = () => {
  const { data: parsedItems, isLoading: isLoadingParsedItems } = useQuery(
    'parsed_ad/count',
    () =>
      getPAdCount({
        ad_status: 'draft',
      }),
    { retry: false },
  );
  const { data: adItems, isLoading: isLoadingAdItems } = useQuery(
    'ad/count',
    () => getAdCount({ listing_status: ['unpublished', 'crm_stored'] }),
    { retry: false },
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '90vh',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 8, md: 8 }}
      >
        <DashboardItem
          title="Парсер"
          value={
            isLoadingParsedItems ? (
              <CircularProgress />
            ) : (
              parsedItems?.count || 0
            )
          }
          href="/parser"
        />
        <DashboardItem
          title="База"
          value={isLoadingAdItems ? <CircularProgress /> : adItems?.count || 0}
          href="/ads"
        />
      </Grid>
    </div>
  );
};
export default Dashboard;
