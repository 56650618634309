import {
  Box,
  Grid,
  IconButton,
  Link,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { ImageCarousel } from '../shared/ImageCarousel';
import TextWidget from '../shared/TextWidget';
import { dateFormatter, getRawAdData, isAdReadyToPublish } from '../../helpers';
import { getParsingItemsFields } from './PAdItemFields';

import '../../styles/Parser.css';
import { useContext, useMemo, useState } from 'react';
import EditPAdDialog from './EditPAdDialog';
import { isEmpty } from 'lodash';
import PromptDialog from '../shared/dialogs/PromptDialog';
import { updatePAd } from '../../api/pAd';
import { useMutation } from 'react-query';
import ConfirmDialog from '../shared/dialogs/ConfirmDialog';
import { DistrictRCContext } from '../../contexts/DistrictRCContext';

const PAdItem = ({ pAd, filter, refetch }) => {
  const adData = getRawAdData(pAd);

  let imageList;

  if (!isEmpty(pAd.ad?.images)) {
    imageList = pAd.ad?.images;
  } else {
    imageList = pAd.images || [];
  }

  const { districtsInfo, RCInfo } = useContext(DistrictRCContext);

  const leftTableItems = [
    {
      name: 'source_id',
      title: 'OLX_ID',
      widget: TextWidget,
      value: adData.source_id,
    },
    {
      name: 'created_at',
      title: 'Парсінг',
      widget: TextWidget,
      value: dateFormatter(adData.created_at),
    },
    {
      name: 'source_created_at',
      title: 'Опубліковано',
      widget: TextWidget,
      value: dateFormatter(adData.source_created_at),
    },
  ];

  const rightTableItems = getParsingItemsFields({
    adData,
    districtsInfo,
    RCInfo,
  });

  const isPostponed = filter.is_postponed === 'True';
  const isTrash = filter.is_trash === 'True';

  const [activeDialogKey, setActiveDialogKey] = useState(null);

  const onDialogClose = () => {
    setActiveDialogKey(null);
  };

  const { mutate } = useMutation({
    mutationFn: updatePAd,
    onSuccess: () => {
      refetch();
      onDialogClose();
    },
  });

  const activeDialog = useMemo(() => {
    switch (activeDialogKey) {
      case 'edit':
        return (
          <EditPAdDialog
            initialValues={{ ...adData, images: imageList }}
            pAd={pAd}
            onClose={onDialogClose}
          />
        );
      case 'postpone':
        return (
          <PromptDialog
            onClose={onDialogClose}
            body="Введіть коментар у полі нижче."
            title="Відкласти"
            inputLabel="Коментар"
            onConfirm={value => {
              mutate({
                id: pAd.id,
                data: {
                  ...pAd,
                  is_postponed: true,
                  comment: value,
                },
              });
            }}
          />
        );
      case 'trash':
        return (
          <PromptDialog
            onClose={onDialogClose}
            body="Введіть коментар у полі нижче."
            title="В смітник"
            inputLabel="Коментар"
            onConfirm={value => {
              mutate({
                id: pAd.id,
                data: {
                  ...pAd,
                  is_trash: true,
                  comment: value,
                },
              });
            }}
          />
        );
      case 'base':
        return (
          <ConfirmDialog
            onClose={onDialogClose}
            title="Додати в базу?"
            onConfirm={() => {
              mutate({
                id: pAd.id,
                data: {
                  ...pAd,
                  ad: { ...pAd.ad, listing_status: 'unpublished' },
                },
              });
            }}
          />
        );

      default:
        return null;
    }
  }, [activeDialogKey]);

  return (
    <Box sx={{ width: 1 }}>
      {activeDialog}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Typography
          variant="h4"
          component="h4"
          sx={{ mb: 1, cursor: 'pointer' }}
        >
          {adData?.source_label || 'Заголовок'}
        </Typography>
        {!!adData.source_link && (
          <IconButton sx={{ marginLeft: 1 }} aria-label="open in new window">
            <Link href={adData.source_link} target="_blank">
              <OpenInNewIcon />
            </Link>
          </IconButton>
        )}
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Box position="relative">
            <ImageCarousel imageList={imageList} />
          </Box>
          <Box sx={{ margin: 1 }}>
            {leftTableItems.map(item => {
              return (
                <Box key={item.name}>
                  <item.widget
                    key={item.name}
                    title={item.title}
                    value={item.value}
                  ></item.widget>
                </Box>
              );
            })}
          </Box>
        </Grid>

        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: '1rem 2rem',
          }}
        >
          <ul className="apartment-fields-wrapper">
            {rightTableItems.map(item => {
              if (item.name === 'comment' && !isPostponed && !isTrash) {
                return null;
              }
              return (
                <li className="apartment-field-item" key={item.name}>
                  <item.widget
                    title={item.title}
                    value={item.value}
                  ></item.widget>
                </li>
              );
            })}
          </ul>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="outlined"
              sx={{ marginRight: 2 }}
              onClick={() => {
                setActiveDialogKey('edit');
              }}
            >
              Редагувати
            </Button>
            {!isPostponed && (
              <Button
                variant="contained"
                sx={{ marginRight: 2 }}
                onClick={() => {
                  setActiveDialogKey('postpone');
                }}
              >
                Відкласти
              </Button>
            )}
            {!isTrash && (
              <Button
                variant="contained"
                sx={{ marginRight: 2 }}
                onClick={() => {
                  setActiveDialogKey('trash');
                }}
              >
                В смітник
              </Button>
            )}
            <Button
              variant="contained"
              sx={{ marginRight: 2 }}
              onClick={() => {
                setActiveDialogKey('base');
              }}
              disabled={!isAdReadyToPublish(pAd)}
            >
              В базу
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 2 }} />
    </Box>
  );
};

export default PAdItem;
