import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import AddEditAdForm from './AddEditAdForm';
import { useMutation } from 'react-query';
import { updateAd } from '../../api/ad';

const EditAdDialog = ({ ad, onClose, refetch }) => {
  const { mutate, isLoading } = useMutation({
    mutationFn: updateAd,
    onSuccess: () => {
      refetch();
      onClose();
    },
  });
  const { id, ...initialValues } = ad;

  const onSubmit = formData => {
    mutate({ id, data: { ...formData, id } });
  };

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>Редагувати оголошення</DialogTitle>
      <DialogContent>
        <AddEditAdForm
          onSubmit={onSubmit}
          onCancel={onClose}
          isLoading={isLoading}
          initialValues={initialValues}
        ></AddEditAdForm>
      </DialogContent>
    </Dialog>
  );
};

export default EditAdDialog;
