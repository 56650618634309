import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from '@mui/material';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import PAdFilters, { pAdFiltersNames } from '../../constants/pAdFilters';
import { useQuery } from 'react-query';
import { getPAds } from '../../api/pAd';
import PAdItem from './PAdItem';
import pAdSortingOptions from '../../constants/pAdSortingOptions';

const PAdListPage = () => {
  const [sortBy, setSortBy] = useState(pAdSortingOptions.CREATED_AT_DESC);

  const { filter: filterName } = useParams();
  const navigate = useNavigate();

  const filter = PAdFilters[filterName];

  useEffect(() => {
    if (!filter) {
      navigate('/parser');
    }
  }, [filter, navigate]);

  const title = pAdFiltersNames[filterName];

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
  });

  const page = searchParams.get('page');

  const {
    data: resultData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(['p_ads', filterName, page, sortBy], () =>
    getPAds({
      ...filter,
      page,
      sort_by: sortBy.value,
    }),
  );

  const data = resultData?.items || [];
  const pagesCount = resultData?.pages || 0;

  const isSearchFinishedAndResultsEmpty = useMemo(
    () => isSuccess && !data.length,
    [data.length, isSuccess],
  );

  const changePage = useCallback(
    (_, page) => {
      setSearchParams({ page });
    },
    [setSearchParams],
  );

  return (
    <>
      <Typography
        variant="h4"
        sx={{ marginBottom: 2, marginTop: 2, fontWeight: 'bold' }}
      >
        {title}
      </Typography>
      {isSearchFinishedAndResultsEmpty && (
        <Typography variant="h5">Немає записів</Typography>
      )}
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!isSearchFinishedAndResultsEmpty && !isLoading && (
        <>
          <Box sx={{ mb: 5 }}>
            <Typography variant="h6">Сортування</Typography>
            <Select value={sortBy} onChange={e => setSortBy(e.target.value)}>
              {Object.keys(pAdSortingOptions).map(option => {
                const sortingOption = pAdSortingOptions[option];
                return (
                  <MenuItem key={option} value={sortingOption}>
                    {sortingOption.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Grid container spacing={1}>
            {data?.map(pAdItemDetails => {
              return (
                <PAdItem
                  pAd={pAdItemDetails}
                  key={pAdItemDetails.id}
                  filter={filter}
                  refetch={refetch}
                />
              );
            })}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: '2rem',
            }}
          >
            <Pagination page={+page} count={pagesCount} onChange={changePage} />
          </Box>
        </>
      )}
    </>
  );
};

export default PAdListPage;
