import React, { useState } from 'react';
import { Button, Container, Input, Typography } from '@mui/material';
import api from '../../api/server';

const FileUpload = ({ onUploadSuccess, onUploadError, adId = 'new' }) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = event => {
    const files = event.target.files;
    if (files) {
      setFile(files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setUploadStatus('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', adId); // Append the S3 path to the form data

    try {
      const response = await api.post('/files/upload/', formData);

      if (response.status === 200) {
        setUploadStatus('File uploaded successfully.');
        onUploadSuccess && onUploadSuccess(response.data);
      } else {
        setUploadStatus(`Error: ${response.statusText}`);
        onUploadError && onUploadError(response);
      }
    } catch (error) {
      setUploadStatus(`Error: ${error.message}`);
      onUploadError && onUploadError(error);
    }
  };

  return (
    <Container sx={{ mb: 2 }}>
      <Input type="file" onChange={handleFileChange} />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        sx={{ ml: 2 }}
      >
        Upload
      </Button>
      <Typography variant="body2" color="textSecondary">
        {uploadStatus}
      </Typography>
    </Container>
  );
};

export default FileUpload;
