import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { router } from './router';
import { ThemeProvider } from '../contexts/ThemeContext';

import 'dayjs/locale/uk';
import { DistrictRCProvider } from '../contexts/DistrictRCContext';
dayjs.locale('uk');
dayjs.extend(relativeTime);

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <DistrictRCProvider>
          <RouterProvider router={router} />
        </DistrictRCProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
