import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';

const PromptDialog = ({
  title,
  body,
  inputLabel,
  onConfirm,
  onClose,
  cancelBtnLabel = 'Відмінити',
  confirmBtnLabel = 'Підтвердити',
  initialValue = '',
}) => {
  const [inputValue, setInputValue] = useState(initialValue);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
        <TextField
          margin="dense"
          label={inputLabel}
          type="text"
          fullWidth
          variant="standard"
          multiline
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{cancelBtnLabel}</Button>
        <Button onClick={() => onConfirm(inputValue)}>{confirmBtnLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;
