import { createContext } from 'react';
import { useUser } from '../hooks/useUser';
import { useQuery } from 'react-query';
import { getDistricts, getResidentialComplexes } from '../api/common';

export const DistrictRCContext = createContext({
  districtsInfo: [],
  RCInfo: [],
});

export const DistrictRCProvider = ({ children }) => {
  const { user: { city } = {} } = useUser();

  const { data: districtsInfo } = useQuery(
    ['districts', city],
    () => getDistricts({ city }),
    {
      enabled: !!city,
      retry: false,
    },
  );

  const { data: RCInfo } = useQuery(
    ['residential-complexes', city],
    () => getResidentialComplexes({ city }),
    { enabled: !!city, retry: false },
  );

  return (
    <DistrictRCContext.Provider value={{ districtsInfo, RCInfo }}>
      {children}
    </DistrictRCContext.Provider>
  );
};
