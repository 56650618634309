import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { SIGN_IN_BASE_URL } from '../constants/routes';
import { useLocalStorage } from './useLocalStorage';

export const useAuth = () => {
  const navigate = useNavigate();

  const {
    value: token,
    setItem: setToken,
    removeItem: removeToken,
  } = useLocalStorage('token');

  const handleLogin = useCallback(
    value => {
      setToken(value);
      navigate('/');
    },
    [navigate, setToken],
  );

  const handleLogout = useCallback(() => {
    removeToken();
    navigate(SIGN_IN_BASE_URL);
  }, [navigate, setToken]);

  useEffect(() => {
    // Check if the URL contains an access_token parameter
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    if (accessToken) {
      handleLogin(accessToken);
    }
  }, [handleLogin]);

  return useMemo(
    () => ({
      token,
      login: handleLogin,
      logout: handleLogout,
    }),
    [token, handleLogin, handleLogout],
  );
};
