import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
} from '@mui/material';
import map from 'lodash/map';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DistrictRCContext } from '../../contexts/DistrictRCContext';
import childrenOptions from '../../constants/childrenOptions';
import petsOptions from '../../constants/petsOptions';
import { useTimeout } from '../../hooks/useTimeout';

const GridBreak = () => <Box sx={{ width: '100%' }} />;

const yesNoOptions = [
  {
    name: 'Так',
    id: 'True',
  },
  {
    name: 'Ні',
    id: 'False',
  },
];

const roomOptions = [...Array(69).keys()].map(i => ({
  name: '' + (i + 1),
  id: '' + (i + 1),
}));

const AdFilters = ({ filter, setFilter }) => {
  const { districtsInfo = [], RCInfo = [] } = useContext(DistrictRCContext);

  const onSelectChange = (key, val) => {
    const ids = map(val, 'id');
    setFilter({
      ...filter,
      [key]: ids.length ? ids : null,
    });
  };

  const [street, setStreet] = useTimeout(() =>
    setFilter({ ...filter, street }),
  );

  const [olxId, setOlxId] = useTimeout(() =>
    setFilter({ ...filter, olx_id: olxId }),
  );

  const [idValue, setIdValue] = useTimeout(() =>
    setFilter({ ...filter, id: idValue }),
  );

  const [rentPriceFrom, setRentPriceFrom] = useTimeout(() =>
    setFilter({ ...filter, rent_price_from: rentPriceFrom }),
  );
  const [rentPriceTo, setRentPriceTo] = useTimeout(() =>
    setFilter({ ...filter, rent_price_to: rentPriceTo }),
  );

  return (
    <Grid container spacing={1} sx={{ mb: 5 }}>
      <Grid item xs={2} sx={{ mt: 2, mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Id</InputLabel>
          <Input onChange={e => setIdValue(e.target.value)} />
        </FormControl>
      </Grid>
      <Grid item xs={2} sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel>OLX id</InputLabel>
          <Input onChange={e => setOlxId(e.target.value)} />
        </FormControl>
      </Grid>
      <Grid item xs={2} sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Ціна від</InputLabel>
          <Input onChange={e => setRentPriceFrom(e.target.value)} />
        </FormControl>
      </Grid>
      <Grid item xs={2} sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Ціна до</InputLabel>
          <Input onChange={e => setRentPriceTo(e.target.value)} />
        </FormControl>
      </Grid>
      <GridBreak />
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Вулиця</InputLabel>
          <Input onChange={e => setStreet(e.target.value)} />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          multiple
          options={districtsInfo}
          onChange={(_, data) => onSelectChange('district_id', data)}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Район" />}
        />
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          multiple
          options={RCInfo.filter(rc =>
            filter.district_id?.includes(rc.district_id),
          )}
          onChange={(_, data) => onSelectChange('residential_complex_id', data)}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="ЖК" />}
        />
      </Grid>

      <GridBreak />

      <Grid item xs={2} sx={{ mt: 2 }}>
        <Autocomplete
          options={yesNoOptions}
          onChange={(_, data) => setFilter({ ...filter, in_center: data?.id })}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Центр" />}
        />
      </Grid>
      <Grid item xs={2} sx={{ mt: 2 }}>
        <Autocomplete
          options={yesNoOptions}
          onChange={(_, data) =>
            setFilter({ ...filter, newly_built: data?.id })
          }
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Новобудова" />}
        />
      </Grid>
      <Grid item xs={2} sx={{ mt: 2 }}>
        <Autocomplete
          multiple
          options={roomOptions}
          onChange={(_, data) => onSelectChange('rooms', data)}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Кімнати" />}
        />
      </Grid>
      <Grid item xs={3} sx={{ mt: 2 }}>
        <Autocomplete
          multiple
          options={Object.entries(childrenOptions).map(([id, name]) => ({
            id,
            name,
          }))}
          onChange={(_, data) => onSelectChange('children', data)}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Діти" />}
        />
      </Grid>
      <Grid item xs={3} sx={{ mt: 2 }}>
        <Autocomplete
          multiple
          options={Object.entries(petsOptions).map(([id, name]) => ({
            id,
            name,
          }))}
          onChange={(_, data) => onSelectChange('pets', data)}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Тварини" />}
        />
      </Grid>
    </Grid>
  );
};

export default AdFilters;
