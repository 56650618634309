import React from 'react';
import { Navigate } from 'react-router-dom';

import { SIGN_IN_BASE_URL } from '../constants/routes';

const ProtectedRoute = ({ children }) => {
  return localStorage.getItem('token') ? (
    <>{children}</>
  ) : (
    <Navigate to={SIGN_IN_BASE_URL} replace />
  );
};

export default ProtectedRoute;
