import PromptDialog from './PromptDialog';
import { useMutation, useQueryClient } from 'react-query';
import { createDistrict } from '../../../api/common';
import { useUser } from '../../../hooks/useUser';

const CreateDistrictDialog = ({ onClose, initialValue }) => {
  const { user: { city } = {} } = useUser();

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: createDistrict,
    onSuccess: async () => {
      await queryClient.refetchQueries('districts');
      onClose();
    },
  });

  return (
    <PromptDialog
      onClose={onClose}
      body="Введіть назву району"
      title="Створити район"
      inputLabel="Назва"
      onConfirm={name =>
        mutate({
          name,
          city,
        })
      }
      initialValue={initialValue}
    />
  );
};

export default CreateDistrictDialog;
