import { useEffect, useMemo, useState } from 'react';
import AdList from './AdList';
import AdFilters from './AdFilters';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import adFilters, { adFiltersNames } from '../../constants/adFilters';
import NewAdDialog from './NewAdDialog';
import { useQueryClient } from 'react-query';

const AdFilterListPage = () => {
  const { filter: filterName } = useParams();
  const pageFilter = adFilters[filterName];
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageFilter) {
      navigate('/ads');
    }
  }, [pageFilter, navigate]);
  const [filter, setFilter] = useState({});
  const title = adFiltersNames[filterName];

  const queryClient = useQueryClient();

  const [activeDialogKey, setActiveDialogKey] = useState(null);

  const onDialogClose = () => {
    setActiveDialogKey(null);
  };

  const activeDialog = useMemo(() => {
    switch (activeDialogKey) {
      case 'new':
        return (
          <NewAdDialog
            onClose={onDialogClose}
            refetch={() => queryClient.refetchQueries('ads')}
          />
        );

      default:
        return null;
    }
  }, [activeDialogKey]);

  return (
    <>
      {activeDialog}
      <Box sx={{ display: 'flex', m: 2 }}>
        <Typography variant="h4" sx={{ m: 2, fontWeight: 'bold' }}>
          {title}
        </Typography>
        {filterName === 'unpublished' && (
          <Button
            variant="contained"
            sx={{ height: 50, mt: 1 }}
            onClick={() => setActiveDialogKey('new')}
          >
            Створити нове оголошення
          </Button>
        )}
      </Box>
      <AdFilters filter={filter} setFilter={setFilter} />
      <AdList filter={{ ...pageFilter, ...filter }} />
    </>
  );
};

export default AdFilterListPage;
