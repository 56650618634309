import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  Grid,
  Select,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Autocomplete,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { updatePAd } from '../../api/pAd';
import { pick } from 'lodash';
import adFields from '../../constants/adFields';
import petsOptions from '../../constants/petsOptions';
import childrenOptions from '../../constants/childrenOptions';
import DraggableImageGallery from '../shared/DraggableImageGallery';
import CreateDistrictDialog from '../shared/dialogs/CreateDistrictDialog';
import CreateRCDialog from '../shared/dialogs/CreateRCDialog';
import ContactForm from '../shared/ContactForm';
import currency from '../../constants/currency';
import { DistrictRCContext } from '../../contexts/DistrictRCContext';

const GridBreak = () => <Box sx={{ width: '100%' }} />;

const EditPAdDialog = ({ initialValues, onClose, pAd }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...initialValues,
      contact: {
        ...initialValues?.contact,
        phone_number: initialValues?.contact?.phone_number?.join(', '),
      },
    },
  });

  const RequiredError = ({ field }) => (
    <Typography variant="subtitle2" sx={{ color: 'red', height: 22 }}>
      {errors[field]?.type === 'required' && 'Обовʼязкове поле'}
    </Typography>
  );

  const [images, setImages] = useState(
    initialValues.images.map(img => ({ src: img, id: uuidv4() })),
  );

  const selectedDistrictId = useWatch({ control, name: 'district_id' });
  const selectedRCId = useWatch({ control, name: 'residential_complex_id' });

  useEffect(() => {
    if (
      RCInfo.find(r => r.id === selectedRCId)?.district_id !==
      selectedDistrictId
    ) {
      setValue('residential_complex_id', null);
      rcInputValue.current = '';
    }
  }, [selectedDistrictId, setValue]);

  const { districtsInfo, RCInfo } = useContext(DistrictRCContext);

  const { mutate, isLoading } = useMutation({
    mutationFn: updatePAd,
    onSuccess: onClose,
  });

  const onSubmit = formData => {
    const data = pAd;

    data.ad = pick(
      {
        ...initialValues,
        ...formData,
      },
      adFields,
    );

    if (!data.ad.images || data.ad.images?.length === 0) return;

    data.ad.images = images.map(img => img.src);

    data.contact = pick(formData.contact, [
      'full_name',
      'status',
      'phone_number',
    ]);

    data.contact.phone_number = formData.contact?.phone_number
      ?.replace(/\s/g, '')
      .split(',');

    mutate({ id: pAd.id, data });
  };

  const districtInputValue = useRef('');
  const rcInputValue = useRef('');

  const [activeDialogKey, setActiveDialogKey] = useState(null);

  const onDialogClose = () => {
    setActiveDialogKey(null);
  };

  const activeDialog = useMemo(() => {
    switch (activeDialogKey) {
      case 'new_district':
        return (
          <CreateDistrictDialog
            onClose={onDialogClose}
            initialValue={districtInputValue.current}
          />
        );
      case 'new_rc':
        return (
          <CreateRCDialog
            initialName={rcInputValue.current}
            initialDistrictId={selectedDistrictId}
            onClose={onDialogClose}
          />
        );
      default:
        return null;
    }
  }, [activeDialogKey]);

  return (
    <Dialog open maxWidth="lg" fullWidth onClose={onClose}>
      <DialogTitle>Редагувати оголошення </DialogTitle>
      <DialogContent sx={{ pt: '30px !important' }}>
        {activeDialog}
        <Grid container columnSpacing={2} rowSpacing={5}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="district_id"
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    options={districtsInfo}
                    value={
                      typeof field.value === 'string'
                        ? districtsInfo.find(r => r.id === field.value)
                        : field.value || null
                    }
                    onChange={(_, data) => onChange(data?.id)}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...field}
                        label="Район"
                        onChange={e =>
                          (districtInputValue.current = e.target.value)
                        }
                      />
                    )}
                    noOptionsText={
                      <Button
                        sx={{ width: '100%', height: '100%' }}
                        onClick={() => setActiveDialogKey('new_district')}
                      >
                        + Створити новий
                      </Button>
                    }
                  />
                )}
              />
              <RequiredError field="district_id" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <Controller
                control={control}
                name="residential_complex_id"
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...field } }) => (
                  <Autocomplete
                    disabled={!selectedDistrictId}
                    options={RCInfo.filter(
                      r => r.district_id === selectedDistrictId,
                    )}
                    value={
                      typeof field.value === 'string'
                        ? RCInfo.find(r => r.id === field.value)
                        : field.value || null
                    }
                    onChange={(_, data) => onChange(data?.id)}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...field}
                        label="ЖК"
                        onChange={e => (rcInputValue.current = e.target.value)}
                      />
                    )}
                    noOptionsText={
                      <Button
                        sx={{ width: '100%', height: '100%' }}
                        onClick={() => setActiveDialogKey('new_rc')}
                      >
                        + Створити новий
                      </Button>
                    }
                  />
                )}
              />
              <RequiredError field="residential_complex_id" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <InputLabel>Вулиця</InputLabel>
              <Input {...register('street', { required: true })}></Input>
              <RequiredError field="street" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <InputLabel>Будинок</InputLabel>
              <Input
                {...register('building_number', { required: true })}
              ></Input>
              <RequiredError field="building_number" />
            </FormControl>
          </Grid>
          <GridBreak />

          <Grid item xs={1}>
            <FormControl>
              <FormControlLabel
                {...register('is_center')}
                control={<Checkbox defaultChecked={initialValues.is_center} />}
                label="Центр"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <FormControlLabel
                {...register('newly_built')}
                control={
                  <Checkbox defaultChecked={initialValues.newly_built} />
                }
                label="Новобудова"
              />
            </FormControl>
          </Grid>
          <GridBreak />

          <Grid item xs={3}>
            <FormControl>
              <InputLabel>К-сть кімнат</InputLabel>
              <Input {...register('rooms', { required: true })}></Input>
              <RequiredError field="rooms" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <InputLabel>Площа</InputLabel>
              <Input {...register('living_area', { required: true })}></Input>
              <RequiredError field="living_area" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <InputLabel>Поверх</InputLabel>
              <Input {...register('floor', { required: true })}></Input>
              <RequiredError field="floor" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <InputLabel>Всього поверхів</InputLabel>
              <Input
                {...register('amount_of_floors', { required: true })}
              ></Input>
              <RequiredError field="amount_of_floors" />
            </FormControl>
          </Grid>
          <GridBreak />

          <Grid item xs={3}>
            <FormControl>
              <InputLabel>Ціна</InputLabel>
              <Input {...register('rent_price', { required: true })}></Input>
              <RequiredError field="rent_price" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  backgroundColor: '#444',
                  pl: 1,
                  pr: 1,
                }}
              >
                Валюта
              </InputLabel>
              <Controller
                control={control}
                name="currency"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={initialValues.currency || currency.UAH}
                  >
                    {Object.keys(currency).map(option => {
                      const label = currency[option];
                      return (
                        <MenuItem key={option} value={option}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <RequiredError field="currency" />
            </FormControl>
          </Grid>
          <GridBreak />
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  backgroundColor: '#444',
                  pl: 1,
                  pr: 1,
                }}
              >
                Тварини
              </InputLabel>
              <Controller
                control={control}
                name="pets"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select {...field} defaultValue={initialValues.pets || ''}>
                    {Object.keys(petsOptions).map(option => {
                      const label = petsOptions[option];
                      return (
                        <MenuItem key={option} value={option}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <RequiredError field="pets" />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel
                sx={{
                  backgroundColor: '#444',
                  pl: 1,
                  pr: 1,
                }}
              >
                Діти
              </InputLabel>
              <Controller
                control={control}
                name="children"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={initialValues.children || ''}
                  >
                    {Object.keys(childrenOptions).map(option => {
                      const label = childrenOptions[option];
                      return (
                        <MenuItem key={option} value={option}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <RequiredError field="children" />
            </FormControl>
          </Grid>
          <GridBreak />
          <ContactForm
            register={register}
            control={control}
            initialValues={initialValues?.contact}
          ></ContactForm>
          <GridBreak />
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Додаткова інформація"
                multiline
                rows={4}
                {...register('additional_info')}
              ></TextField>
            </FormControl>
          </Grid>
          <GridBreak />
          <Grid item xs={12}>
            <DraggableImageGallery
              images={images}
              setImages={setImages}
              adId={pAd.id}
            />
            <Typography variant="subtitle2" sx={{ color: 'red', height: 22 }}>
              {images.length === 0 && 'Завантажте хоча б одну картинку'}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'end' }}>
          <Button onClick={handleSubmit(onSubmit)}>
            {!isLoading ? 'Зберегти' : <CircularProgress />}
          </Button>
          <Button onClick={onClose}>Відміна</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditPAdDialog;
