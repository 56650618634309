import api from './server';

export const getPAdCount = async params => {
  const response = await api.get(`/parsed-ads/count`, { params });
  return response.data;
};

export const getPAds = async params => {
  const response = await api.get('/parsed-ads/', { params });
  return response.data;
};

export const updatePAd = async ({ id, data }) => {
  const response = await api.put(`/parsed-ads/${id}/`, data);

  return response.data;
};
