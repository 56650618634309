export default {
  CREATED_AT_DESC: {
    value: 'created_at_desc',
    label: 'Спаршено (від новішого до старішого)',
  },
  SOURCE_CREATED_AT_DESC: {
    value: 'source_created_at_desc',
    label: 'Створено (від новішого до старішого)',
  },
  CREATED_AT: {
    value: 'created_at',
    label: 'Спаршено (від старішого до новішого)',
  },
  SOURCE_CREATED_AT: {
    value: 'source_created_at',
    label: 'Створено (від старішого до новішого)',
  },
};
