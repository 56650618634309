export default [
  'district_id',
  'residential_complex_id',
  'street',
  'building_number',
  'is_center',
  'newly_built',
  'rooms',
  'living_area',
  'floor',
  'amount_of_floors',
  'rent_price',
  'currency',
  'children',
  'pets',
  'tg_link',
  'geo_link',
  'additional_info',
  'images',
  'source_label',
];
