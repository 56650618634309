import { Grid } from '@mui/material';
import { useQuery } from 'react-query';
import DashboardItem from '../shared/DashboardItem';
import { PARSER_BASE_URL } from '../../constants/routes';

import PAdFilters from '../../constants/pAdFilters';
import { getPAdCount } from '../../api/pAd';

const MENU_ITEMS = [
  {
    title: 'Всі Оголошення',
    href: `${PARSER_BASE_URL}/all`,
    filter: 'all',
  },
  {
    title: 'Оголошення від власників',
    href: `${PARSER_BASE_URL}/owners`,
    filter: 'owners',
  },
  {
    title: 'Ціна вища',
    href: `${PARSER_BASE_URL}/priceChangeUp`,
    filter: 'priceChangeUp',
  },
  {
    title: 'Ціна нижча',
    href: `${PARSER_BASE_URL}/priceChangeDown`,
    filter: 'priceChangeDown',
  },
  {
    title: 'Відкладені',
    href: `${PARSER_BASE_URL}/postponed`,
    filter: 'postponed',
  },
  {
    title: 'Смітник',
    href: `${PARSER_BASE_URL}/trash`,
    filter: 'trash',
  },
];

const DashboardItemWrapper = ({ item }) => {
  const { data, isLoading } = useQuery(
    ['ad/count', item.filter],
    () => getPAdCount(PAdFilters[item.filter]),
    {
      retry: false,
    },
  );

  return (
    <DashboardItem
      key={item.href}
      title={item.title}
      value={data?.count || 0}
      href={item.href}
      isLoading={isLoading}
    />
  );
};

const ParserPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '70vh',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={1} direction="row" sx={{ mt: 1 }}>
        {MENU_ITEMS.map(item => (
          <DashboardItemWrapper
            item={item}
            key={`${item.href}-dashboard-item`}
          />
        ))}
      </Grid>
    </div>
  );
};

export default ParserPage;
