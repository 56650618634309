import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          my: 10,
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '30vh',
        }}
      >
        <Typography component="h1" variant="h2" gutterBottom>
          Упс, щось пішло не так
        </Typography>
        <Typography component="p" variant="h4" gutterBottom>
          Сторінка не знайдена, спробуйте пізніше
        </Typography>
        <Button variant="outlined" onClick={() => navigate('/')}>
          Повернутись на головну
        </Button>
      </Box>
    </div>
  );
};

export default ErrorPage;
