import React from 'react';
import { Grid, Card, CardMedia, Button } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CancelIcon from '@mui/icons-material/Cancel';

import FileUpload from './FileUpload';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableImageGallery = ({ images, setImages, adId }) => {
  const onDragEnd = result => {
    if (!result.destination) return;
    const items = reorder(
      images,
      result.source.index,
      result.destination.index,
    );
    setImages(items);
  };

  const handleUploadSuccess = data => {
    const newImages = images.concat({
      id: `image-${images.length}`,
      src: data.url,
    });
    setImages(newImages);
  };

  const onCrossClick = id => {
    const newImages = images.filter(img => img.id !== id);
    setImages(newImages);
  };

  return (
    <>
      <FileUpload onUploadSuccess={handleUploadSuccess} adId={adId} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-gallery" direction="horizontal">
          {provided => (
            <Grid
              container
              spacing={2}
              wrap="nowrap"
              sx={{ overflow: 'scroll' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {images.map((image, index) => (
                <Draggable key={image.id} draggableId={image.id} index={index}>
                  {provided => (
                    <Grid
                      item
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Card sx={{ position: 'relative' }}>
                        <Button
                          sx={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            minWidth: 0,
                            minHeight: 0,
                            padding: 0,
                          }}
                          onClick={() => onCrossClick(image.id)}
                        >
                          <CancelIcon
                            sx={{
                              borderRadius: '50%',
                              width: 15,
                              height: 15,
                              backgroundColor: 'red',
                              color: '#fff',
                            }}
                          />
                        </Button>
                        <CardMedia
                          component="img"
                          image={image.src}
                          alt={`Image ${index}`}
                          sx={{ width: 150, height: 150 }}
                        />
                      </Card>
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DraggableImageGallery;
