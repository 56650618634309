import { useState } from 'react';

export const useLocalStorage = (key, initValue = null) => {
  const [value, setValue] = useState(localStorage.getItem(key));

  const setItem = newValue => {
    localStorage.setItem(key, newValue);
    setValue(newValue);
  };

  const getItem = () => {
    return localStorage.getItem(key) || initValue;
  };

  const removeItem = () => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return { value, setItem, getItem, removeItem };
};
