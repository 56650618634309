import { Grid } from '@mui/material';
import { useQuery } from 'react-query';
import DashboardItem from '../shared/DashboardItem';
import { AD_BASE_URL } from '../../constants/routes';
import { getAdCount } from '../../api/ad';
import adFilters, { adFiltersNames } from '../../constants/adFilters';
import { keys } from 'lodash';

const MENU_ITEMS = keys(adFilters).map(key => ({
  title: adFiltersNames[key],
  href: `${AD_BASE_URL}/${key}`,
  filter: key,
}));

const DashboardItemWrapper = ({ item }) => {
  const { data, isLoading } = useQuery(
    ['ad/count', item.filter],
    () => getAdCount(adFilters[item.filter]),
    {
      retry: false,
    },
  );

  return (
    <DashboardItem
      key={item.href}
      title={item.title}
      value={data?.count || 0}
      href={item.href}
      isLoading={isLoading}
    />
  );
};

const AdPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '70vh',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={1} direction="row" sx={{ mt: 1 }}>
        {MENU_ITEMS.map(item => (
          <DashboardItemWrapper
            item={item}
            key={`${item.href}-dashboard-item`}
          />
        ))}
      </Grid>
    </div>
  );
};

export default AdPage;
