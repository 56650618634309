import React, { useEffect, useState } from 'react';

import {
  ToggleOffOutlined as ToggleOffOutlinedIcon,
  Menu as MenuIcon,
  MapsHomeWork as MapsHomeWorkIcon,
  AccountCircle as AccountCircleIcon,
  ModeNight as ModeNightIcon,
  LightMode as LightModeIcon,
} from '@mui/icons-material';
import {
  Toolbar,
  Button,
  AppBar,
  IconButton,
  Box,
  Typography,
  Menu,
  Container,
  Tooltip,
  MenuItem,
} from '@mui/material';
import { SIGN_IN_BASE_URL, SIGN_OUT_BASE_URL } from '../../constants/routes';
import { useTheme } from '../../hooks/useTheme';
import { useUser } from '../../hooks/useUser';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const { theme, setTheme } = useTheme();
  const { user } = useUser();

  let city = 'Усі оголошення';

  if (user && user.city) {
    switch (user.city) {
      case 'lviv':
        city = 'Львів';
        break;
      case 'kyiv':
        city = 'Київ';
        break;
    }
  }

  const profilePages = [];

  if (!user) {
    profilePages.push({ link: SIGN_IN_BASE_URL, name: 'Увійти' });
  } else {
    profilePages.push({ link: SIGN_OUT_BASE_URL, name: 'Вийти' });
  }

  const [typeOfProperty, setTypeOfProperty] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const navigateTo = path => {
    navigate(path);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleTypeChange = tool => {
    setTypeOfProperty(tool);
    localStorage.setItem('typeOfProperty', tool.toString());
  };

  useEffect(() => {
    setTypeOfProperty(localStorage.getItem('typeOfProperty') === 'true');
  }, []);

  return (
    <AppBar position="static" color="default" elevation={1}>
      <Container maxWidth="xl" sx={{ px: { xs: 0, sm: 3 } }}>
        <Toolbar disableGutters>
          <MapsHomeWorkIcon
            sx={{ display: { xs: 'none', md: 'flex' }, fontSize: 40, mr: 1 }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Real Estate
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Tooltip title="Головне меню">
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {profilePages.map(page => (
                <MenuItem
                  key={page.link}
                  onClick={handleCloseNavMenu}
                  component={RouterLink}
                  to={page.link}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <MapsHomeWorkIcon
            sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, fontSize: 40 }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Real Estate
          </Typography>
          {localStorage.getItem('token') !== null && (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  placeContent: 'start',
                  display: { xs: 'none', md: 'flex' },
                  mr: 2,
                }}
              >
                <Button
                  onClick={() => navigateTo('/parser')}
                  sx={{ display: 'block' }}
                >
                  Парсер
                </Button>
                <Button
                  onClick={() => navigateTo('/ads')}
                  sx={{ display: 'block' }}
                >
                  База
                </Button>
              </Box>
              <Box sx={{ flexGrow: 2, display: 'flex' }}>
                <Typography>{city}</Typography>
              </Box>
            </>
          )}
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Профіль користувача">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircleIcon fontSize={'large'} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {profilePages.map(page => (
                <MenuItem
                  key={page.link}
                  onClick={handleCloseUserMenu}
                  component={RouterLink}
                  to={page.link}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>

            <IconButton aria-label={'Тема'} sx={{ ml: 1 }} color="inherit">
              <ToggleOffOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label={'Тема'}
              sx={{ ml: 1 }}
              onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
              color="inherit"
            >
              {theme === 'dark' ? <LightModeIcon /> : <ModeNightIcon />}
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
