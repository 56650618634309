export default {
  unpublished: {
    listing_status: 'unpublished',
    ad_status: 'active',
  },
  active: {
    listing_status: 'crm_stored',
    ad_status: 'active',
  },
  archived: { listing_status: 'crm_stored', ad_status: 'archived' },
};

export const adFiltersNames = {
  unpublished: 'Неопубліковані',
  active: 'Активні',
  archived: 'Архівні',
};
