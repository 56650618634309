import { Typography, useTheme } from '@mui/material';

const CheckBoxWidget = ({ title, value }) => {
  const theme = useTheme();
  const checkboxValue = value;

  const CheckboxText = {
    True: 'Так',
    False: 'Ні',
  };

  return (
    <Typography sx={{ color: theme.palette.text.secondary }} component="span">
      <Typography
        sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}
        component="span"
      >
        {title}:{' '}
      </Typography>
      {value === undefined || value === null
        ? '-'
        : checkboxValue
        ? CheckboxText.True
        : CheckboxText.False}
    </Typography>
  );
};

export default CheckBoxWidget;
