import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

const ConfirmDialog = ({
  title,
  onConfirm,
  onClose,
  cancelBtnLabel = 'Відмінити',
  confirmBtnLabel = 'Підтвердити',
}) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{cancelBtnLabel}</Button>
        <Button onClick={() => onConfirm()}>{confirmBtnLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
