import { Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import '../../styles/ImageCarousel.css';

const DefaultSettingsT = {
  animation: 'fade',
  indicators: false,
  navButtonsAlwaysVisible: true,
  cycleNavigation: true,
  fullHeightHover: true,
  swipe: true,
  autoPlay: false,
};

export const ImageCarouselItem = ({ imageUrl }) => (
  <Box className="carousel-image-wrapper">
    <div className="carousel-image-shadow"></div>
    <img className="carousel-image-item" src={imageUrl} alt={imageUrl} />
  </Box>
);

export const ImageCarousel = ({ imageList }) => (
  <Carousel
    navButtonsProps={{
      style: {
        height: 30,
        width: 30,
      },
    }}
    {...DefaultSettingsT}
  >
    {imageList.map((image, i) => (
      <ImageCarouselItem key={i} imageUrl={image} />
    ))}
  </Carousel>
);
