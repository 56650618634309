import { useEffect, useRef, useState } from 'react';

export const useTimeout = (callback, delay = 300) => {
  const [value, setValue] = useState('');
  const t = useRef(null);
  useEffect(() => {
    clearTimeout(t.current);

    t.current = setTimeout(callback, delay);
  }, [value]);

  return [value, setValue];
};
