import dayjs from 'dayjs';
import adFields from './constants/adFields';
import { isNil, isArray } from 'lodash';

export const checkAndFormatEmptyText = value =>
  (typeof value === 'string' || typeof value === 'number') && !!value
    ? String(value)
    : '-';

export const dateFormatter = value => (value ? dayjs(value).fromNow() : '-');

export const priceFormatter = price =>
  price ? new Intl.NumberFormat().format(Number(price)) : '-';

export const floorFormatter = (floor, amount_of_floors) =>
  `${floor ? floor : '-'}/${amount_of_floors ? amount_of_floors : '-'}`;

export const getRawAdData = parsedAd => {
  if (!parsedAd.ad) parsedAd.ad = {};
  const { ad, ...parsed } = parsedAd;

  const result = { ...parsed };

  adFields.forEach(key => {
    result[key] = !isNil(ad[key]) ? ad[key] : parsed[key];
  });

  return result;
};

export const isAdReadyToPublish = pAd => {
  const { ad } = pAd;
  return (
    ad.district_id &&
    ad.residential_complex_id &&
    ad.street &&
    ad.building_number &&
    ad.rooms &&
    ad.living_area &&
    ad.floor &&
    ad.amount_of_floors &&
    ad.rent_price &&
    ad.currency &&
    ad.pets &&
    ad.children &&
    pAd.contact &&
    pAd.contact.full_name &&
    pAd.contact.phone_number
  );
};

export const prepareQuery = params => {
  let result = '?';
  for (let key in params) {
    if (isArray(params[key])) {
      params[key].forEach(value => {
        result += `${key}=${value}&`;
      });
    } else if (params[key]) {
      result += `${key}=${params[key]}&`;
    }
  }
  return result.substring(0, result.length - 1);
};
