import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAds } from '../../api/ad';
import AdItem from './AdItem';

const AdList = ({ filter }) => {
  const { filter: filterName } = useParams();

  const [searchParams, setSearchParams] = useSearchParams({
    page: '1',
  });

  const page = searchParams.get('page');

  const [data, setData] = useState([]);

  const onFetchSuccess = data => {
    setData(data.items);
  };

  const {
    data: resultData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(
    ['ads', page, filter],
    () =>
      getAds({
        ...filter,
        page,
      }),
    { onSuccess: onFetchSuccess },
  );

  const pagesCount = resultData?.pages || 0;

  const isSearchFinishedAndResultsEmpty = useMemo(
    () => isSuccess && !data.length,
    [data.length, isSuccess],
  );

  const changePage = useCallback(
    (_, page) => {
      setSearchParams({ page });
    },
    [setSearchParams],
  );

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {isSearchFinishedAndResultsEmpty && (
        <Typography variant="h5">Немає записів</Typography>
      )}

      {!isSearchFinishedAndResultsEmpty && !isLoading && (
        <>
          <Grid container spacing={1}>
            {data?.map(adDetails => {
              return (
                <AdItem
                  ad={adDetails}
                  key={adDetails.id}
                  refetch={refetch}
                  filter={filterName}
                />
              );
            })}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: '2rem',
            }}
          >
            <Pagination page={+page} count={pagesCount} onChange={changePage} />
          </Box>
        </>
      )}
    </>
  );
};

export default AdList;
