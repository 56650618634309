import api from './server';

export const getDistricts = async params => {
  const response = await api.get('/districts/', { params });
  return response.data;
};

export const getResidentialComplexes = async params => {
  const response = await api.get('/residential-complexes/', { params });
  return response.data;
};

export const createDistrict = async body => {
  const response = await api.post('/districts/', body);
  return response.data;
};

export const createRC = async body => {
  const response = await api.post('/residential-complexes/', body);
  return response.data;
};
