import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import SignInPage from './SignIn';
import {
  SIGN_IN_BASE_URL,
  ERROR_BASE_URL,
  SIGN_OUT_BASE_URL,
  PARSER_BASE_URL,
  AD_BASE_URL,
} from '../constants/routes';
import HomeLayout from './Layouts/HomeLayout';
import PrivateRoute from './PrivateRoute';
import ErrorPage from './Error';
import SignOutPage from './SignOut';
import Dashboard from './Dashboard';
import ParserPage from './ParserPage/ParserPage';
import PAdListPage from './ParserPage/PAdListPage';
import AdPage from './AdPage/AdPage';
import AdFilterListPage from './AdPage/AdFilterListPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <HomeLayout>
              <Dashboard />
            </HomeLayout>
          </PrivateRoute>
        ),
      },
      {
        path: `${SIGN_IN_BASE_URL}`,
        element: (
          <HomeLayout>
            <SignInPage />
          </HomeLayout>
        ),
      },
      {
        path: `${SIGN_OUT_BASE_URL}`,
        element: (
          <HomeLayout>
            <SignOutPage />
          </HomeLayout>
        ),
      },
      {
        path: `${PARSER_BASE_URL}`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <ParserPage />
            </HomeLayout>
          </PrivateRoute>
        ),
      },
      {
        path: `${PARSER_BASE_URL}/:filter/:page?`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <PAdListPage />
            </HomeLayout>
          </PrivateRoute>
        ),
      },
      {
        path: `${AD_BASE_URL}`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <AdPage />
            </HomeLayout>
          </PrivateRoute>
        ),
      },
      {
        path: `${AD_BASE_URL}/:filter/:page?`,
        element: (
          <PrivateRoute>
            <HomeLayout>
              <AdFilterListPage />
            </HomeLayout>
          </PrivateRoute>
        ),
      },
      {
        path: `${ERROR_BASE_URL}`,
        element: (
          <HomeLayout>
            <ErrorPage />
          </HomeLayout>
        ),
      },
      {
        path: '*',
        element: <Navigate to={ERROR_BASE_URL} replace />,
      },
    ],
  },
]);
