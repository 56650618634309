import api from './server';

export async function register(data) {
  const response = await api.post(`/auth/register`, data);
  return response.data;
}

export async function login(data) {
  const formData = new FormData();
  formData.append('username', data.username);
  formData.append('password', data.password);
  const response = await api.post(`/auth/jwt/login`, formData);
  return response.data;
}

export async function getProfile() {
  const response = await api.get(`/users/identity`);
  return response.data;
}
