import { useQuery } from 'react-query';
import { getProfile } from '../api/auth';
import { useLocalStorage } from './useLocalStorage';

export const useUser = () => {
  const { value: token } = useLocalStorage('token');

  const { data: user, isLoading: isUserLoading } = useQuery(
    'users/identity',
    getProfile,
    {
      enabled: !!token,
      retry: false,
    },
  );

  return { user, isUserLoading };
};
