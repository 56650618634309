import React, { useState } from 'react';

import {
  CardActions,
  CardContent,
  Link,
  TextField,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { login as loginRequest } from '../../api/auth';
import BoxCard from '../shared/BoxCard';
import { useAuth } from '../../hooks/useAuth';

const AUTH_BOT = process.env.REACT_APP_AUTH_BOT || 'real-estate-admin'; //@TODO: change it
const SignInPage = () => {
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = event => {
    setFormData(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage('');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await loginRequest(formData);
      login(response.access_token);
    } catch (error) {
      setErrorMessage('Неправильний логін або пароль');
    }
  };

  const telegramAuthLink = `https://t.me/${AUTH_BOT}?start=auth`;

  return (
    <div>
      <BoxCard title="Увійти">
        <CardContent>
          {errorMessage && (
            <Typography color="error" sx={{ pb: 3 }}>
              {errorMessage}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField
                label="Логін"
                variant="outlined"
                fullWidth
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                required
              />
              <TextField
                label="Пароль"
                variant="outlined"
                fullWidth
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <Button variant="contained" type="submit" fullWidth>
                Увійти
              </Button>
            </Stack>
          </form>
        </CardContent>
        <CardActions sx={{ p: 2, pt: 1 }}>
          <Typography variant="body2" color="text.secondary">
            <Link href={telegramAuthLink}>Увійти за допомогою Telegram</Link>
          </Typography>
        </CardActions>
      </BoxCard>
    </div>
  );
};

export default SignInPage;
