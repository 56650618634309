import React, { useContext, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { createRC } from '../../../api/common';
import { useMutation, useQueryClient } from 'react-query';
import { useUser } from '../../../hooks/useUser';
import { DistrictRCContext } from '../../../contexts/DistrictRCContext';

const CreateRCDialog = ({
  onClose,
  initialName = '',
  initialDistrictId = null,
}) => {
  const { user: { city } = {} } = useUser();

  const { districtsInfo } = useContext(DistrictRCContext);

  const [name, setName] = useState(initialName);
  const [selectedDistrict, setSelectedDistrict] = useState(
    districtsInfo.find(d => d.id === initialDistrictId),
  );
  const [acInputValue, setAcInputValue] = useState('');
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: createRC,
    onSuccess: async () => {
      await queryClient.refetchQueries('residential-complexes');
      onClose();
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Створити ЖК</DialogTitle>
      <DialogContent>
        <DialogContentText>Оберіть район і введіть назву ЖК</DialogContentText>
        <Autocomplete
          sx={{ mb: 3, mt: 3 }}
          options={districtsInfo}
          value={selectedDistrict || null}
          onChange={(_, data) => setSelectedDistrict(data)}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Район" />}
          inputValue={acInputValue}
          onInputChange={(_, newInputValue) => {
            setAcInputValue(newInputValue);
          }}
        />
        <TextField
          margin="dense"
          label="Назва"
          type="text"
          fullWidth
          variant="standard"
          multiline
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Відмінити</Button>
        <Button
          onClick={() =>
            mutate({
              name,
              city,
              district_id: selectedDistrict.id,
            })
          }
        >
          Підтвердити
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRCDialog;
