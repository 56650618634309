import React from 'react';

import { Container } from '@mui/material';
import Header from './Header';

const HomeLayout = ({ children }) => {
  return (
    <div className="layout-content-wrapper">
      <Header />
      <Container maxWidth="xl" sx={{ px: { xs: 1, sm: 2 } }}>
        <div>{children}</div>
      </Container>
    </div>
  );
};

export default HomeLayout;
