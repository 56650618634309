import { Typography, useTheme } from '@mui/material';
import { checkAndFormatEmptyText } from '../../helpers';

const TextWidget = ({ title, value }) => {
  const theme = useTheme();

  return (
    <Typography sx={{ color: theme.palette.text.secondary }} component="span">
      <Typography
        sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}
        component="span"
      >
        {title}:
      </Typography>{' '}
      {checkAndFormatEmptyText(value)}
    </Typography>
  );
};

export default TextWidget;
