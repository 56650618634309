import React, { useContext, useMemo, useState } from 'react';
import { Box, Grid, Typography, Button, Divider } from '@mui/material';
import { ImageCarousel } from '../shared/ImageCarousel';
import { getAdItemsFields } from './AdItemFields';
import EditAdDialog from './EditAdDialog';

import '../../styles/Parser.css';
import PromptDialog from '../shared/dialogs/PromptDialog';
import { useMutation } from 'react-query';
import { updateAd } from '../../api/ad';
import ConfirmDialog from '../shared/dialogs/ConfirmDialog';
import { DistrictRCContext } from '../../contexts/DistrictRCContext';

const AdItem = ({ ad, refetch, filter }) => {
  const imageList = ad?.images || [];

  const { districtsInfo, RCInfo } = useContext(DistrictRCContext);

  const rightTableItems = getAdItemsFields({
    adData: ad,
    districtsInfo,
    RCInfo,
  });

  const { mutate } = useMutation({
    mutationFn: updateAd,
    onSuccess: () => {
      refetch();
      onDialogClose();
    },
  });

  const [activeDialogKey, setActiveDialogKey] = useState(null);

  const onDialogClose = () => {
    setActiveDialogKey(null);
  };

  const activeDialog = useMemo(() => {
    switch (activeDialogKey) {
      case 'edit':
        return (
          <EditAdDialog ad={ad} onClose={onDialogClose} refetch={refetch} />
        );
      case 'publish':
        return (
          <PromptDialog
            onClose={onDialogClose}
            body="Перевірте телеграм"
            title="Опублікувати"
            inputLabel="Телеграм"
            initialValue={ad.tg_link}
            onConfirm={value => {
              if (!value) return;

              mutate({
                id: ad.id,
                data: {
                  ...ad,
                  tg_link: value,
                  listing_status: 'crm_stored',
                  ad_status: 'active',
                },
              });
            }}
          />
        );
      case 'archive':
        return (
          <ConfirmDialog
            onClose={onDialogClose}
            title="Архівувати?"
            onConfirm={() => {
              mutate({
                id: ad.id,
                data: {
                  ...ad,
                  listing_status: 'crm_stored',
                  ad_status: 'archived',
                },
              });
            }}
          />
        );
      case 'activate':
        return (
          <ConfirmDialog
            onClose={onDialogClose}
            title="Активувати?"
            onConfirm={() => {
              mutate({
                id: ad.id,
                data: {
                  ...ad,
                  listing_status: 'crm_stored',
                  ad_status: 'active',
                },
              });
            }}
          />
        );
      default:
        return null;
    }
  }, [activeDialogKey]);

  return (
    <Box sx={{ width: 1 }}>
      {activeDialog}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Typography
          variant="h4"
          component="h4"
          sx={{ mb: 1, cursor: 'pointer' }}
        >
          {ad?.source_label || 'Заголовок'}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Box position="relative">
            <ImageCarousel imageList={imageList} />
          </Box>
        </Grid>

        <Grid
          item
          xs={9}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: '1rem 2rem',
          }}
        >
          <ul className="apartment-fields-wrapper">
            {rightTableItems.map(item => {
              return (
                <li className="apartment-field-item" key={item.name}>
                  <item.widget
                    title={item.title}
                    value={item.value}
                  ></item.widget>
                </li>
              );
            })}
          </ul>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
        <Button
          variant="outlined"
          sx={{ marginRight: 2 }}
          onClick={() => {
            setActiveDialogKey('edit');
          }}
        >
          Редагувати
        </Button>
        {filter === 'unpublished' && (
          <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={() => {
              setActiveDialogKey('publish');
            }}
          >
            Опублікувати
          </Button>
        )}
        {filter === 'active' && (
          <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={() => {
              setActiveDialogKey('archive');
            }}
          >
            Архівувати
          </Button>
        )}
        {filter === 'archived' && (
          <Button
            variant="outlined"
            sx={{ marginRight: 2 }}
            onClick={() => {
              setActiveDialogKey('activate');
            }}
          >
            Активувати
          </Button>
        )}
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
    </Box>
  );
};

export default AdItem;
