import { priceFormatter, floorFormatter } from '../../helpers';
import TextWidget from '../shared/TextWidget';
import CheckBoxWidget from '../shared/CheckBoxWidget';
import petsOptions from '../../constants/petsOptions';
import childrenOptions from '../../constants/childrenOptions';

export const getParsingItemsFields = ({ adData, districtsInfo, RCInfo }) => {
  return [
    {
      name: 'district_id',
      title: 'Район',
      widget: TextWidget,
      value: adData?.district_id
        ? districtsInfo?.find(district => district.id === adData?.district_id)
            ?.name
        : undefined,
    },
    {
      name: 'street',
      title: 'Вулиця',
      widget: TextWidget,
      value: adData?.street,
    },
    {
      name: 'building_number',
      title: 'Будинок',
      widget: TextWidget,
      value: adData?.building_number,
    },
    {
      name: 'is_center',
      title: 'Центр',
      widget: CheckBoxWidget,
      value: adData?.is_center,
    },
    {
      name: 'newly_built',
      title: 'Новобудова',
      widget: CheckBoxWidget,
      value: adData?.newly_built,
    },
    {
      name: 'residential_complex_id',
      title: 'ЖК',
      widget: TextWidget,
      value: adData?.residential_complex_id
        ? RCInfo?.find(complex => complex.id === adData?.residential_complex_id)
            ?.name
        : undefined,
    },
    {
      name: 'rooms',
      title: 'Кімнати',
      widget: TextWidget,
      value: adData?.rooms,
    },
    {
      name: 'living_area',
      title: 'Площа (кв. м.)',
      widget: TextWidget,
      value: adData?.living_area,
    },
    {
      name: 'floor',
      title: 'Поверх',
      widget: TextWidget,
      value: floorFormatter(adData?.floor, adData?.amount_of_floors),
    },

    {
      name: 'rent_price',
      title: 'Ціна',
      widget: TextWidget,
      value: priceFormatter(adData?.rent_price),
    },

    {
      name: 'currency',
      title: 'Валюта',
      widget: TextWidget,
      value: adData?.currency,
    },
    {
      name: 'pets',
      title: 'Тварини',
      widget: TextWidget,
      value: !!adData?.pets && petsOptions[adData?.pets],
    },
    {
      name: 'children',
      title: 'Діти',
      widget: TextWidget,
      value: !!adData?.children && childrenOptions[adData?.children],
    },
    {
      name: 'additional_info',
      title: 'Додатково',
      widget: TextWidget,
      value: adData?.additional_info,
    },
    {
      name: 'contact_name',
      title: 'Контактна особа',
      widget: TextWidget,
      value: adData?.contact?.full_name,
    },
    {
      name: 'phone_number',
      title: 'Телефон',
      widget: TextWidget,
      value: adData?.contact?.phone_number?.join(' '),
    },
    {
      name: 'comment',
      title: 'Коментар',
      widget: TextWidget,
      value: adData?.comment,
    },
  ];
};
