import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import contactStatusOptions from '../../constants/contactStatusOptions';
import { Controller } from 'react-hook-form';

const ContactForm = ({ register, control, initialValues }) => {
  return (
    <>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel>Контактна особа</InputLabel>
          <Input {...register('contact.full_name')}></Input>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel
            sx={{
              backgroundColor: '#444',
              pl: 1,
              pr: 1,
            }}
          >
            Статус
          </InputLabel>
          <Controller
            control={control}
            name="contact.status"
            render={({ field }) => (
              <Select {...field} defaultValue={initialValues?.status || ''}>
                {Object.keys(contactStatusOptions).map(option => {
                  const label = contactStatusOptions[option];
                  return (
                    <MenuItem key={option} value={option}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>Телефон (через кому)</InputLabel>
          <Input {...register('contact.phone_number')}></Input>
        </FormControl>
      </Grid>
    </>
  );
};

export default ContactForm;
