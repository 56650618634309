import React, { createContext } from 'react';

import { CssBaseline, useMediaQuery } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { useLocalStorage } from '../hooks/useLocalStorage';

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const DEFAULT_THEME = 'dark';
export const ThemeContext = createContext({
  theme: DEFAULT_THEME,
  setTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const { value, getItem, setItem } = useLocalStorage('theme');

  const getOrDefault = theme => {
    if (!theme) {
      theme = prefersDarkMode ? 'dark' : 'light';
    }
    return theme;
  };

  const handleToggleTheme = () => {
    const updatedValue = getOrDefault(getItem());
    setItem(updatedValue !== 'dark' ? 'dark' : 'light');
  };

  const themeContextValue = {
    theme: getOrDefault(value),
    setTheme: handleToggleTheme,
  };

  const customizations =
    themeContextValue.theme === 'dark'
      ? {
          palette: {
            mode: themeContextValue.theme,
            background: {
              default: '#121212',
              paper: '#1e1e1e',
            },
          },
          //dark theme
        }
      : {
          //light theme
          palette: {
            mode: themeContextValue.theme,
            background: {
              default: '#fff',
              paper: '#e8e8e8',
            },
            text: {
              primary: '#2e3739',
              secondary: '#425053',
              disabled: '#ebeae5',
            },
          },
        };

  const themeObj = createTheme({
    ...customizations,
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  });

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <MuiThemeProvider theme={themeObj}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
