import React from 'react';

import { Box, Card, CardHeader } from '@mui/material';

const BoxCard = ({ title, children }) => {
  return (
    <Box sx={{ my: 4 }}>
      <Card sx={{ maxWidth: 400, margin: 'auto' }}>
        <CardHeader title={title} sx={{ pb: 1 }} />
        {children}
      </Card>
    </Box>
  );
};

export default BoxCard;
