import api from './server';
import { prepareQuery } from '../helpers';

export const getAds = async params => {
  const query = prepareQuery(params);

  const response = await api.get('/ads/' + query);
  return response.data;
};

export const getAdCount = async params => {
  const query = prepareQuery(params);

  const response = await api.get('/ads/count' + query);
  return response.data;
};

export const createAd = async data => {
  const response = await api.post('/ads/', data);
  return response.data;
};

export const updateAd = async ({ id, data }) => {
  const response = await api.put(`/ads/${id}/`, data);
  return response.data;
};
