export default {
  all: {
    contact_is_owner: 'False',
    object_already_exist: 'False',
    is_postponed: 'False',
    is_trash: 'False',
    ad_status: 'draft',
  },
  owners: {
    contact_is_owner: 'True',
    is_postponed: 'False',
    is_trash: 'False',
    object_already_exist: 'False',
    ad_status: 'draft',
  },
  priceChangeUp: {
    price_change: 'up',
    object_already_exist: 'True',
    is_postponed: 'False',
    is_trash: 'False',
    ad_status: 'draft',
  },
  priceChangeDown: {
    price_change: 'down',
    object_already_exist: 'True',
    is_postponed: 'False',
    is_trash: 'False',
    ad_status: 'draft',
  },
  postponed: { is_postponed: 'True', is_trash: 'False', ad_status: 'draft' },
  trash: { is_postponed: 'False', is_trash: 'True', ad_status: 'draft' },
};

export const pAdFiltersNames = {
  all: 'Всі оголошення',
  owners: 'Оголошення від власників',
  priceChangeUp: 'Ціна виросла',
  priceChangeDown: 'Ціна знизилась',
  postponed: 'Відкладені оголошення',
  trash: 'Смітник',
};
