import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import { BorderLinearProgress } from './BorderLinearProgress';

function DashboardItem({ title, value, progress, href = '', isLoading }) {
  return (
    <Grid item md={4} xs={12}>
      <Card className="ProductCard">
        <Box sx={progress ? { p: 6 } : { p: 7.25 }}>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '96px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Stack direction="column" alignContent={'flex-start'}>
              <Typography variant="h3">{value}</Typography>
              {typeof progress !== 'undefined' && (
                <BorderLinearProgress variant="determinate" value={progress} />
              )}
              <Link href={href} underline={'hover'}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  {title}
                </Typography>
              </Link>
            </Stack>
          )}
        </Box>
      </Card>
    </Grid>
  );
}

export default DashboardItem;
